import React from 'react';
import { TakeoverAd } from 'js/components/specific/top/TakeoverAd/type';

export type Props = {
  takeoverAd?: TakeoverAd;
  children: React.ReactNode;
};

export const TakeoverAdPresenter: React.FC<Props> = ({
  takeoverAd,
  children,
}) => {
  const targetProps = takeoverAd?.isTargetBlank ? { target: '_blank' } : {};
  return takeoverAd ? (
    <div className="takeover-ad" style={{ background: takeoverAd.background }}>
      <a
        className="takeover-ad__header-link"
        href={takeoverAd.linkUrl}
        {...targetProps}
      >
        <img
          className="takeover-ad__header-image"
          src={takeoverAd.headerBannerUrl}
          alt={takeoverAd.description}
        />
      </a>
      <div className="takeover-ad__wrapper">
        <a
          className="takeover-ad__side-link position-left"
          href={takeoverAd.linkUrl}
          {...targetProps}
        >
          <img
            className="takeover-ad__side-image"
            src={takeoverAd.firstSideBannerUrl}
            alt={takeoverAd.description}
          />
        </a>
        <a
          className="takeover-ad__side-link position-right"
          href={takeoverAd.linkUrl}
          {...targetProps}
        >
          <img
            className="takeover-ad__side-image"
            src={
              takeoverAd.secondSideBannerUrl || takeoverAd.firstSideBannerUrl
            }
            alt={takeoverAd.description}
          />
        </a>
        {children}
      </div>
    </div>
  ) : (
    <div className="top-contents-wrapper">{children}</div>
  );
};
