import React from 'react';
import { DailyContentsItem } from 'js/components/common/layout/DailyContents/DailyContentsItem';
import classNames from 'classnames';
import { DailyContentsItemWithKey } from 'js/components/common/layout/DailyContents/DailyContentsList/type';
import { ColumnType } from 'js/types/gridList';
import { NEW_CONTENT_URLS } from 'js/components/common/layout/DailyContents/DailyContentsList/const';

export type Props = {
  list: DailyContentsItemWithKey[];
  column: ColumnType;
};

export const DailyContentsListPresenter: React.FC<Props> = ({
  list,
  column,
}) => {
  const listItems = list.map((listItem) => {
    const isNew = NEW_CONTENT_URLS.includes(listItem.itemUrl);
    return <DailyContentsItem {...listItem} key={listItem.key} isNew={isNew} />;
  });

  return (
    <ul
      className={classNames(
        'daily-contents-window__list',
        'u_grid',
        `u_column-${column}`,
        'u_bg-white',
      )}
    >
      {listItems}
    </ul>
  );
};
