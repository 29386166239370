import React from 'react';
import { logout } from 'js/utils/login';

export type Props = {
  isLogin: boolean | undefined;
};

export const WideGlobalFooterLinksPresenter: React.FC<Props> = ({
  isLogin,
}) => (
  <>
    <div className="wide-global-footer__contents-group">
      <p className="wide-global-footer__contents-category">
        ショッピングで貯まる
      </p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/5/"
          >
            生活・暮らし
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/6/"
          >
            美容・健康
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/11/"
          >
            クーポン・宅配
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/13/"
          >
            本・動画・エンタメ・ホビー
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/17/"
          >
            総合通販・百貨店・スーパー
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/18/"
          >
            ファッション
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/19/"
          >
            パソコン・家電
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/20/"
          >
            ベビー・キッズ・マタニティ
          </a>
        </li>
      </ul>
    </div>
    <div className="wide-global-footer__contents-group">
      <p className="wide-global-footer__contents-category">サービスで貯まる</p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/1/"
          >
            クレジットカード
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/2/"
          >
            キャッシング・ローン
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/3/"
          >
            銀行・証券・FX
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/4/"
          >
            不動産・保険
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/7/"
          >
            国内旅行
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/8/"
          >
            海外旅行
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/9/"
          >
            航空券
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/10/"
          >
            レンタカー
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/12/"
          >
            仕事・資格・教育
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/14/"
          >
            通信・プロバイダ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/15/"
          >
            アンケート・モニター
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/search/category/16/"
          >
            アプリ・ゲーム
          </a>
        </li>
      </ul>
    </div>
    <div className="wide-global-footer__contents-group">
      <p className="wide-global-footer__contents-category">
        アンケートで貯まる
      </p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/research/">
            アンケート
          </a>
        </li>
      </ul>
      <p className="wide-global-footer__contents-category">モニターで貯まる</p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/monitor/">
            モニター
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/monitor/ponponmonitor/"
          >
            ポンポンモニター
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/monitor/tentamemonitor/"
          >
            テンタメモニター
          </a>
        </li>
      </ul>
      <p className="wide-global-footer__contents-category">
        お友達紹介で貯まる
      </p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/contents/invite/"
          >
            お友達紹介
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/contents/invite/blog/"
          >
            ブログで紹介
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/contents/invite/material/"
          >
            紹介用の画像素材
          </a>
        </li>
      </ul>
      <p className="wide-global-footer__contents-category">
        キャンペーンで貯まる
      </p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/campaign/">
            キャンペーン
          </a>
        </li>
      </ul>
    </div>
    <div className="wide-global-footer__contents-group">
      <p className="wide-global-footer__contents-category">貯まるコンテンツ</p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/mainichi_news/"
          >
            ニュースで貯まる
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/otoku_mail/">
            お得メールで貯まる
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/smile_project/click_fund/"
          >
            募金で貯まる
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/contents/smart_phone/"
          >
            スマホ用メルマガ受信で貯まる
          </a>
        </li>
      </ul>
      <p className="wide-global-footer__contents-category">ECナビ公式</p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/card_plus/">
            ECナビカードプラス
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/contents/line_stamp/"
          >
            LINEスタンプ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/smile_project/"
          >
            ECナビ募金
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="https://hikaku.ecnavi.jp/"
          >
            ECナビ 比較
          </a>
        </li>
      </ul>
    </div>
    <div className="wide-global-footer__contents-group">
      <ul className="wide-global-footer__contents-list has-frame">
        <li>
          <p className="wide-global-footer__contents-category">マイページ</p>
        </li>
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/mypage/">
            マイページ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/mypage/ex_select/"
          >
            ポイント交換
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/mypage/point_history/"
          >
            ポイント履歴
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/mypage/affiliate_history/"
          >
            ショップ&サービス利用履歴
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/mypage/point_limit/"
          >
            ポイント有効期限
          </a>
        </li>
        {isLogin && (
          <li className="wide-global-footer__contents-item">
            <a
              className="wide-global-footer__contents-link"
              href="/logout/"
              onClick={(e) => logout(e)}
              rel="nofollow"
            >
              ログアウト
            </a>
          </li>
        )}
      </ul>
      <p className="wide-global-footer__contents-category">
        カスタマーサービス
      </p>
      <ul className="wide-global-footer__contents-list">
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/information/importance/"
          >
            重要なお知らせ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/help/">
            ヘルプ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/support/">
            お問い合わせ
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a className="wide-global-footer__contents-link" href="/guide/">
            ECナビガイド
          </a>
        </li>
        <li className="wide-global-footer__contents-item">
          <a
            className="wide-global-footer__contents-link"
            href="/members_club/"
          >
            メンバーズクラブガイド
          </a>
        </li>
      </ul>
    </div>
  </>
);
