import React from 'react';
import {
  TrendingWordSkeletonListPresenter,
  Props as PresenterProps,
} from 'js/components/common/layout/TrendingWord/TrendingWordSkeletonList/presenter';

export type Props = PresenterProps;

export const TrendingWordSkeletonList: React.FC<Props> = (props) => (
  <TrendingWordSkeletonListPresenter {...props} />
);
