import React from 'react';
import { range } from 'js/utils/helper';

export type Props = {
  isTitleVisible?: boolean;
};

export const TrendingWordSkeletonListPresenter: React.FC<Props> = ({
  isTitleVisible = false,
}) => (
  <>
    <div className="trending-word">
      {isTitleVisible && <p className="trending-word__heading">注目ワード</p>}
      <ul className="trending-word__list">
        {range(1, 5).map((num) => (
          <li className="trending-word__skeleton-item" key={num}>
            &nbsp;
          </li>
        ))}
      </ul>
    </div>
  </>
);
