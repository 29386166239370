import { HttpError } from 'js/utils/http/client';
import { useRequestImmutable } from 'js/hooks/useRequest';
import { useLayoutData } from 'js/hooks/useLayoutData';
import { TrendingWordType } from 'js/components/common/layout/TrendingWord/type';

export type TrendingWordResponse = {
  trendingWord: TrendingWordType[];
  trendingWordInServiceMenu: TrendingWordType[];
};

type Props = {
  isReady: boolean;
};
export const useTrendingWord = (
  options = {
    isReady: true,
  } as Partial<Props>,
): {
  trendingWordList: TrendingWordType[];
  trendingWordListInServiceMenu: TrendingWordType[];
} => {
  const { isReady } = options;
  // サーバーへの負荷を考慮してviewに埋め込まれたデータから注目ワードの取得を試みて、存在しない場合に初めてAPIリクエストを行う
  // ref: https://github.com/voyagegroup/ecnavi/issues/14094
  const { layoutData, isLoadingLayoutData } = useLayoutData();
  const { data } = useRequestImmutable<TrendingWordResponse, HttpError>(
    isReady && !isLoadingLayoutData && !layoutData
      ? ['/api/trending_word/']
      : null,
  );
  if (!isLoadingLayoutData && layoutData) {
    return {
      trendingWordList: layoutData.trendingWord ?? [],
      trendingWordListInServiceMenu: layoutData.trendingWordInServiceMenu ?? [],
    };
  }

  return {
    trendingWordList: data?.trendingWord ?? [],
    trendingWordListInServiceMenu: data?.trendingWordInServiceMenu ?? [],
  };
};
