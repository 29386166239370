import React from 'react';
import { NotificationDot } from 'js/components/ui/Notification/NotificationDot';
import classNames from 'classnames';

export type Props = {
  itemUrl: string;
  imageUrl: string;
  name: string;
  title?: string;
  hasNotification?: boolean;
  isTargetBlank?: boolean;
  isNew?: boolean;
};

export const DailyContentsItemPresenter: React.FC<Props> = ({
  itemUrl,
  imageUrl,
  name,
  title,
  hasNotification = false,
  isTargetBlank = false,
  isNew = false,
}) => {
  const blank = isTargetBlank ? { target: '_blank' } : {};

  return (
    <li className="daily-contents-window__item u_grid__item">
      <a
        className={classNames('daily-contents-window__link', 'c_blocklink', {
          'has-notification': hasNotification,
        })}
        href={itemUrl}
        title={title}
        {...blank}
      >
        <img
          className="daily-contents-window__image"
          src={imageUrl}
          alt=""
          width="120"
          height="120"
        />
        <p className="daily-contents-window__name">{name}</p>
        {hasNotification && <NotificationDot />}
        {isNew && (
          <span className="daily-contents-window__new c_new c_corner" />
        )}
      </a>
    </li>
  );
};
