import { useEffect, useState } from 'react';
import { UserInfoResponse } from 'js/hooks/useUserInfo';
import { TrendingWordType } from 'js/components/common/layout/TrendingWord/type';

export interface GlobalLayoutDataType {
  isSimpleHeader: boolean;
  isResponsive: boolean;
  isLogoH1: boolean;
  signupUrl: string;
  searchWord: string;
  userInfo: UserInfoResponse | null;
  trendingWord: TrendingWordType[];
  trendingWordInServiceMenu: TrendingWordType[];
}

export const useLayoutData = () => {
  const [layoutData, setLayoutData] = useState<
    GlobalLayoutDataType | undefined
  >();
  const [isLoadingLayoutData, setIsLoadingLayoutData] = useState(true);

  useEffect(() => {
    // React化されていないページではPHPのlayoutファイルに埋め込まれた内容からデータを取得する
    // id="__GLOBAL_LAYOUT_DATA__"の要素は以下のPC, SPそれぞれlayoutファイルに存在する
    // PC: lib/common/view/global/layout/parts/body_default.php
    // SP: sites/ecnavi/apps/view/m/global/layout.php
    const content = document.querySelector(
      '#__GLOBAL_LAYOUT_DATA__',
    )?.textContent;
    if (content) {
      const data = JSON.parse(content) as GlobalLayoutDataType;
      setLayoutData(data);
    }
    setIsLoadingLayoutData(false);
  }, []);

  return { layoutData, isLoadingLayoutData };
};
